<template>
  <div>
    <Breadscrumbs :items="breadcrumbs" class="header-bread-scrum" />
    <v-layout column>
      <item-partner-list-view
        v-for="item in dataLst"
        :key="item.id"
        :item="item"
      />
    </v-layout>
    <!-- Dialog loading -->
    <dialog-loading :is-show="showLoading" :message="$t('processing')" />

    <!-- notifications -->
    <dialog-notification
      v-model="showNoti"
      :icon-src="getIcon"
      :message="message"
    />

    <dialog-confirm-token-expried v-model="showConfirmTokenExpried" />
  </div>
</template>

<script>
import Breadscrumbs from "@/components/base/Breadscrumbs";
import DialogLoading from "@/components/dialog/DialogLoading.vue";
import DialogNotification from "@/components/dialog/DialogNotification.vue";
import ItemPartnerListView from "./components/ItemPartnerListView.vue";
import DialogConfirmTokenExpried from "@/components/dialog/DialogConfirmTokenExpried.vue";
import constants from "@/constants";
import { StringUtils } from "@/helpers/stringUtils";
import { BaseService } from "@/services/baseService";
import { StorageService } from "@/services/storageService";
export default {
  components: {
    DialogLoading,
    DialogNotification,
    ItemPartnerListView,
    DialogConfirmTokenExpried,
    Breadscrumbs,
  },
  data: () => ({
    dataLst: [],
    // loading
    showLoading: false,
    showConfirmTokenExpried: false,
    // notifications
    showNoti: false,
    typeNoti: constants.typeAlert.warning,
    message: "",
    currentRole: JSON.parse(localStorage.getItem("CURRENT_USER")).roles,
    currentPoId: JSON.parse(localStorage.getItem("CURRENT_USER")).poId,
    breadcrumbs: [
      {
        text: "partner_list",
        disabled: true,
        isActive: true,
      },
    ],
  }),
  computed: {
    getIcon() {
      return StringUtils.getIconSrc(this.typeIcon);
    },
  },
  created() {
    if (
      (window.location.pathname &&
        !this.currentRole.includes("ROLE_ADMIN") &&
        !this.currentRole.includes("ROLE_ROOT")) ||
      this.currentPoId !== constants.postOffice.primary
    ) {
      this.toggleDialogNoti({
        state: true,
        msg: this.$t("no-mission-msg"),
      });
      setTimeout(() => {
        this.$router.go(-1);
      }, constants.timeOutRole);
    }
  },
  beforeMount() {
    this.initData();
  },
  methods: {
    async initData() {
      const results = await this.getPartners();
      if (results !== null && results.length > 0) {
        this.dataLst = results.filter(
          (item, index, self) =>
            index === self.findIndex((t) => t.partnerId === item.partnerId)
        );
      } else {
        this.dataLst = [];
      }
    },
    async getPartners() {
      this.showLoading = true;
      const { status, data } = await BaseService.getLogisticPartner();
      this.showLoading = false;
      if (status === constants.statusCode.ok) {
        return data;
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true;
      }

      return null;
    },
    toggleDialogNoti(
      pars = {
        state: false,
        type: constants.typeAlert.warning,
        msg: this.$t("no-mission-msg"),
      }
    ) {
      this.showNoti = pars.state;
      this.typeNoti = pars.type;
      this.message = pars.msg;
    },
  },
};
</script>

<style src="@/styles/form.scss" lang="scss" />
