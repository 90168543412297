<template>
  <v-layout class="mb-3">
    <v-img
      :src="item.avatar"
      max-width="120px"
      contain
      class="rounded-lg border-gray mr-3"
      alt="Avatar"
    />

    <v-layout column class="rounded-lg border-gray pa-4">
      <v-layout justify-space-between align-center>
        <h4>{{ item ? item.partnerName : "" }}</h4>
        <v-btn
          outlined
          color="primary"
          class="custom-min-width-btn"
          @click="onGoToPartnerList"
        >
          {{ $t("see_price_list") }}
        </v-btn>
      </v-layout>
      <v-layout>
        <v-divider class="my-2 mr-8" />
        <div class="mx-16" />
      </v-layout>
      <v-layout justify-space-between align-center>
        <span>{{ item ? item.partnerDescription : "" }}</span>
        <v-btn
          outlined
          color="primary"
          class="custom-min-width-btn"
          @click="onGotoAccounts"
        >
          {{ $t("account_link") }}
        </v-btn>
      </v-layout>
    </v-layout>
    <!-- <DialogCreateOrUpdateAccount
      v-model="showCreateOrUpdateAccount"
    /> -->
  </v-layout>
</template>

<script>
import constants from "@/constants";
import routePaths from "@/router/routePaths";
import { mapActions } from "vuex";
// import DialogCreateOrUpdateAccount from '@/views/table_price/components/DialogCreateOrUpdateAccount'
export default {
  components: {
    // DialogCreateOrUpdateAccount,
  },
  props: {
    item: {
      type: Object,
      default: null,
      constants: constants,
    },
  },
  data() {
    return {
      constants,
      showCreateOrUpdateAccount: false,
    };
  },
  computed: {
    avatarValid() {
      return this.item && typeof this.item.avatar !== constants.undefined;
    },
  },
  methods: {
    ...mapActions("layout", ["setBreadcrumbs"]),
    onGoToPartnerList() {
      this.$router.push({
        path: routePaths.PARTNER_PRICES_LIST,
        query: { id: this.item.partnerId, name: this.item.partnerName },
      });
    },
    onGotoAccounts() {
      this.$router.push({
        path: routePaths.PARTNER_ACCOUNTS,
        query: {
          id: this.item.partnerId,
          name: this.item.partnerName,
          useShippingMethod: this.item.useShippingMethod,
        },
      });
    },
  },
};
</script>
